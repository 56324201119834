@import "styles/_variable.scss";
@import "styles/_mixin.scss";

// .footer {
//   display: flex;
//   justify-content: space-between;
//   padding: 3em 0;
//   border-top: 1px solid #666;

//   a {
//     color: #41addd;
  
//     &:hover {
//       color: #6cc0e5;
//     }
//   }
// }

.footer {
  display: block;
  padding: 1.5rem 0;
}
.footer .footer-link, .footer .nav .nav-item .nav-link {
  color: color(muted);
}