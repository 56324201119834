
.cursor-pointer {
    cursor: pointer !important;
}

.nav-link {
    cursor: pointer;
}

.p4-5 {
    padding: 2rem !important;
}

.equal,
.equal>div[class*='col-'] {
    display: flex;
    flex-wrap: wrap;
}

.equal>div[class*='col-'] >div {
    width: 100%;
    height: 100%;
    margin: 0;
}

a {
  color: color(primary);
  font-weight: 500;
  transition: all .5s;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
}

a:not(.btn-social-icon):not(.btn-social):not(.page-link) .ion,
a:not(.btn-social-icon):not(.btn-social):not(.page-link) .fas,
a:not(.btn-social-icon):not(.btn-social):not(.page-link) .far,
a:not(.btn-social-icon):not(.btn-social):not(.page-link) .fal,
a:not(.btn-social-icon):not(.btn-social):not(.page-link) .fab {
  margin-left: 4px;
}

.font-weight-normal {
  font-weight: 500 !important;
}

.lead {
  line-height: 34px;
}

@include media-breakpoint-down(xs) {
  .lead {
    font-size: 1.0625rem;
    line-height: 30px;
  }
}

.btn:active, .btn:active:focus, .btn:focus, .custom-select:focus, .form-control:focus {
  outline: none;
}

p, ul:not(.list-unstyled), ol {
  line-height: 28px;
}

.shadow {
  @include shadow;
}

.text-muted {
  color: color(muted) !important;
}

.ct-content>table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem
}

@media (max-width:991.98px) {
  .ct-content>table {
      display: block;
      overflow-x: auto;
      -ms-overflow-style: -ms-autohiding-scrollbar
  }

  .ct-content>table.table-bordered {
      border: 0
  }
}

.ct-content>table>tbody>tr>td,
.ct-content>table>tbody>tr>th,
.ct-content>table>tfoot>tr>td,
.ct-content>table>tfoot>tr>th,
.ct-content>table>thead>tr>td,
.ct-content>table>thead>tr>th {
  padding: 1rem;
  vertical-align: top;
  border: 1px solid #e9ecef
}

.ct-content>table>tbody>tr>td>p:last-child,
.ct-content>table>tbody>tr>th>p:last-child,
.ct-content>table>tfoot>tr>td>p:last-child,
.ct-content>table>tfoot>tr>th>p:last-child,
.ct-content>table>thead>tr>td>p:last-child,
.ct-content>table>thead>tr>th>p:last-child {
  margin-bottom: 0
}

.ct-content>table td:first-child>code {
  white-space: nowrap
}

.ct-content>h2:not(:first-child) {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 3rem
}

.ct-content>h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 2.5rem
}

.ct-content>ol li,
.ct-content>ul li {
  margin-bottom: .25rem
}

.ct-content>ol,
.ct-content>p,
.ct-content>ul {
    font-size: 1.1rem;
    line-height: 1.6rem;
}

@media (min-width:992px) {

  .ct-content>ol,
  .ct-content>p,
  .ct-content>ul {
      max-width: 800px;
  }
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}