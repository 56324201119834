@import "~bootstrap/scss/utilities";

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.mw-800 {
    max-width: 800px;
}