@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Color system
$white:    #ffffff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #8898aa !default;   // Line footer color
$gray-700: #525f7f !default;   // Line p color
$gray-800: #32325d !default;   // Line heading color
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge((
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
), $grays);

$blue:    #5e72e4;
$indigo:  #5603ad;
$purple:  #8965e0;
$pink:    #f3a4b5;
$red:     #f5365c;
$orange:  #fb6340;
$yellow:  #ffd600;
$green:   #2dce89;
$teal:    #11cdef;
$cyan:    #2bffc6;

$default:       #172b4d;
$primary:       #5e72e4;
$secondary:     #f4f5f7;
$success:       $green;
$info:          $teal;
$warning:       $orange;
$danger:        $red;
$light:         $gray-500;
$dark:          $gray-900;
$darker:        darken($gray-900, 15%);

$colors: (
  default:      $default,
  primary:      $primary,
  secondary:    $secondary,
  success:      $success,
  info:         $info,
  warning:      $warning,
  danger:       $danger,
  neutral:      $white,
  dark:         $dark,
  darker:       $darker,
  whitesmoke:     #f7f9f9,
  muted:          #98a6ad,
  body:           #f8f9fe,
  font:           #525f7f,
  fontdark:       #34395e,
);

$colors: map-merge($colors, (
  blue:       $blue,
  indigo:     $indigo,
  purple:     $purple,
  pink:       $pink,
  red:        $red,
  orange:     $orange,
  yellow:     $yellow,
  green:      $green,
  teal:       $teal,
  cyan:       $cyan,
  white:      $white,
  gray:       $gray-600,
  light:      $gray-400,
  lighter:    $gray-200,
  gray-dark:  $gray-800
));

$theme-colors: () !default;
$theme-colors: map-merge($theme-colors, (
  "default":      $default,
  "primary":      $primary,
  "secondary":    $secondary,
  "success":      $success,
  "info":         $info,
  "warning":      $warning,
  "danger":       $danger,
  "white":        $white,
  "neutral":      $white,
  "dark":         $dark,
  "darker":       $darker
));

$navbarSize: 3.5rem;
$footerSize: 3.5rem;

$navbar-height: 4rem;
$navbar-bg-height: 7rem;

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1920px
);

@function color($key) {
	@return map-get($colors, $key);
}

@function color_lighten($key, $amount) {
	@return lighten(map-get($colors, $key), $amount);
}

@function color_darken($key, $amount) {
	@return darken(map-get($colors, $key), $amount);
}

@function to_opacity($color, $opacity) {
  @return rgba(color($color), $opacity);
}