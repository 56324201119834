html {
  overflow-x: hidden;
}

html,
body,
div#DZHAWEB_ADMIN {
  height: 100%;
}

div#DZHAWEB_ADMIN {
  display: flex;
  flex-direction: column;
}

.main-content {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
  flex-shrink: 0 !important;
  overflow: hidden;
  // padding-top: 1rem;
}

@include media-breakpoint-down(xs) {
  main {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.main-sidebar {
  @include shadow();
  position: fixed;
  top: 0;
  height: 100%;
  width: 250px;
  background-color: #fff;
  z-index: 880;
  left: 0;
  overflow: hidden;
  outline: none;
}

.main-sidebar,
.navbar,
.main-content,
.main-footer {
  transition: all .5s;
}

body.sidebar-gone {
  .main-sidebar {
    left: -250px;
  }
}

body.sidebar-mini {
  .hide-sidebar-mini {
    display: none !important;
  }

  .main-sidebar {
    width: 4rem;
    overflow: initial !important;

    .sidebar-brand_lg {
      display: none;
    }

    .sidebar-brand_sm {
      display: block;
    }

    .sidebar-menu {
      >.nav-item {
        padding: .5rem;
        margin-bottom: -.5rem;
        margin-top: -.5rem;

        .nav-link {
          border-radius: 3px;
          // height: 45px;
          padding: 0;
          justify-content: center;

          .nav-link__icon {
            margin: 0;
          }

          .nav-link__text {
            display: none;
          }

          &.active {
            @include icon-shadow(primary);
            background-color: color(primary);
            color: #fff;
          }
          
          &:hover {
            .nav-link__text {
              @include shadow(0.6rem 0 0.6rem 0.3rem);
              margin-top: 0;
              display: block;
              position: absolute;
              left: 3.5rem;
              padding: .5rem;
              z-index: -1;
              min-width: 200px;
              border-top-right-radius: 0.375rem;
              border-bottom-right-radius: 0.375rem;
              background-color: #fff;
              color: color(font);
            }
          }
        }
        
      }
    }
  }

  .main-content {
    padding-left: 65px;
  }
}

.sidebar-menu {
  padding: 0;
  margin: 0;

  >.nav-item {
    display: block;

    .nav-link {
      position: relative;
      display: flex;
      align-items: center;
      height: 3rem;
      // line-height: 3rem;
      padding: 0 20px;
      width: 100%;
      letter-spacing: .3px;
      color: color_lighten(font, 10%);
      text-decoration: none;

      .nav-link__icon {
        width: 28px;
        margin-right: 20px;
        font-size: 1.25rem;
        text-align: center;
      }

      .nav-link__text {
        margin-top: 3px;
        width: 100%;
      }

      &:hover {
        background-color: color_lighten(light, 7.6%);
      }
      
      &.active {
        color: color(primary);
        font-weight: 600;
        padding-left: 16px;
        background-color: transparent;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 1.5rem;
          width: .25rem;
          background-color: color(primary);
        }
      }
    }
  }
}


.sidebar-brand {
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 65px;
  line-height: 65px;
  white-space: nowrap;

  &.sidebar-brand_sm {
    display: none;
    // margin-bottom: -10px;
  }
  // &.sidebar-brand_lg {
  //   margin-bottom: 10px;
  // }

  .sidebar-brand__link {
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 700;
    color: #000;
  }
}

.main-content {
  padding-left: 250px;
  width: 100%;
  // height: 100%;
}

.main-footer {
  padding: 1rem 0;
  margin-top: auto;
  color: color(muted);
  border-top: 1px solid color(light);
  display: block;
  width: 100%;
}

@media (max-width: 1024px) {
  .sidebar-gone-hide {
    display: none !important;
  }

  .sidebar-gone-show {
    display: block !important;
  }

  .main-sidebar {
    position: fixed !important;
    margin-top: 0 !important;
    z-index: 891;
  }

  .main-content {
    padding-left: 0px;
    padding-right: 0px;
    width: 100% !important;
  }

  body {

    &.sidebar-show {
      overflow: hidden;
    }

    &.sidebar-show:before {
      content: '';
      position: fixed;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0;
      z-index: 891;
      -webkit-animation-name: fadeinbackdrop;
      animation-name: fadeinbackdrop;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    @-webkit-keyframes fadeinbackdrop {
      to {
        opacity: .6;
      }
    }

    @keyframes fadeinbackdrop {
      to {
        opacity: .6;
      }
    }
  }
}

.main-content.no-sidebar {
  padding-left: 0px;
}